import React from "react";
import styled from "styled-components";

export default function XmarkIcon() {
  return (
    <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
    </StyledSvg>
  );
}

const StyledSvg = styled.svg`
  width: 40px;
  height: 40px;
  fill: white;

  &:hover {
    fill: red;
  }
`;
