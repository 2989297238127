import MyPageForm from "../components/auth/MyPageForm";
import "./MyPage.css";

export default function Regist() {
  return (
    <div className="mypage-container">
      <MyPageForm />
    </div>
  );
}
