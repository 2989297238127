import NavHeader from "../components/common/NavHeader";
import "./Statistics.css";

export default function Statistics() {
  return (
    <div className="statistics-container">
      <NavHeader />
      <h1>통계</h1>
    </div>
  );
}
