import "./ResultReport.css";
import GlobalGold from "../components/result/GlobalGold";

export default function ResultReport() {
  return (
    <div>
      <h1>결과리포트</h1>
      <GlobalGold />
    </div>
  );
}
